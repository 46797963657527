import { defineStore } from 'pinia'

const api = window.api

export const usePreferencesStore = defineStore('preferences', {
  state: () => ({
    route: '',
    user: {},
    isLoading: false,
    isLoaded: false,
    preferences: {
      activeQueues: [],
      allowedAgents: [],
      allowedQueues: [],
      onlineConfig: {},
      permissions: [],
      tabs: [],
      restrictedAccess: false,
      onlineTVDarkMode: true
    }
  }),

  getters: {
    getActiveQueues (state) {
      return state.preferences.activeQueues
    }
  },

  actions: {
    signOut () {
      window.signOut().catch(() => null).then(() => {
        window.location.href = '/acesso/entrar'
      })
    },

    async getPreferences () {
      if (this.isLoaded) {
        return this.preferences
      }
      if (this.isLoading) {
        await new Promise(resolve => {
          const checkInterval = setInterval(() => {
            if (!this.isLoading) {
              clearInterval(checkInterval)
              resolve()
            }
          }, 100)
        })

        return this.preferences
      }

      const user = await window.getAuthSession()
      this.user = user

      try {
        this.isLoading = true
        const result = await api.get('/cc/user-preferences')
        if (!result?.data) return null

        this.preferences = result?.data
        this.isLoaded = true
        this.isLoading = false

        return Object.assign({}, this.user, this.preferences)
      } catch (error) {
        this.isLoading = false
      }
    },

    async savePreferences (body) {
      try {
        this.preferences.activeQueues = body.activeQueues
        await api.put('/cc/user-preferences', { body })
      } catch (error) {
        console.log({ error })
      }
    }
  }
})
