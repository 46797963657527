<script setup>
import { onMounted, onBeforeUnmount, ref, markRaw, watch } from 'vue'
import VonixMenu from '@/assets/icons/VonixMenu.vue'
import { menuHelpers } from './menu.js'
import UserLogo from '@/assets/icons/UserLogo.vue'

const userLogo = markRaw(UserLogo)
const vonixLogo = markRaw(VonixMenu)

const menu = ref({ list: [], pathActive: true })
const navItem = ref(null)
const dropdown = ref(null)

const props = defineProps(['menuConfig', 'signOut', 'sessionStore'])

onMounted(() => { document.addEventListener('click', (e) => { menuHelpers.outClick(menu, e) }) })
onBeforeUnmount(() => { document.removeEventListener('click', (e) => menuHelpers.outClick(menu, e)) })

const onClickMenu = (e) => { menuHelpers.onClickItem(e, menu); methods.onClickUser() }
const onClickSubItem = (e) => menuHelpers.subItemClick(e, menu)
const goToDashboardAgents = (e) => menuHelpers.goToDashboardAgents(e, menu)

const methods = {
  onClickUser: () => {
    const dropdownEl = dropdown.value
    if (dropdownEl) dropdownEl.style.width = `${navItem.value.offsetWidth}px`
  }
}

watch(props.menuConfig, (newMenu) => { menu.value = newMenu })
</script>

<template>
  <ul class="nav-list">
    <template
      v-for="(item, index) in menu.list"
      :key="index"
    >
      <li
        v-if="item.separator"
        class="nav-item fill"
      />

      <li
        v-else
        class="nav-item item-margin"
      >
        <a
          v-if="item.link "
          :href="item.link"
          @click="onClickSubItem"
          :class="`link-menu ${menu.pathActive && item.active ? 'force-active' : ''}`.trim()"
        >
          <component
            v-if="item.icon"
            :is="item.icon"
            style="margin: 0 12px;"
          />
          <span
            v-if="item.label"
            style="margin: 0 10px 0 5px"
          >{{ item.label }}</span>
        </a>

        <a
          v-else
          href="#"
          @click="onClickMenu"
          :class="`menu ${menu.pathActive && item.active ? 'force-active' : ''}`.trim()"
        >
          <div
            class="menu-trick"
            v-if="index"
          />

          <vonix-logo
            v-else
            style="margin-right: 8px"
            class="vonix-logo"
            @click="goToDashboardAgents"
          />

          <component
            v-if="item.icon"
            :is="item.icon"
            style="margin-left: 10px; color:#FFFFFF"
          />
          <span
            v-if="item.label"
            style="margin: 0 10px 0 5px"
          >{{ item.label }}</span>
        </a>

        <div
          class="dropdown"
          v-if="item.menu && item.menu.length"
          :style="`margin-left: ${index ? '5px' : '23px'}`"
        >
          <span style="display: block; height: 8px" />
          <template
            v-for="(subitem, subindex) in item.menu"
            :key="subindex"
          >
            <template v-if="subitem.separator">
              <div class="nav-separator" />
            </template>

            <template v-if="subitem.title">
              <span class="nav-title">
                {{ subitem.label }}
              </span>
            </template>

            <template v-if="subitem.link">
              <a
                :href="subitem.link"
                @click="onClickSubItem"
                :class="`submenu ${subitem.active ? 'active' : ''}`.trim()"
              >{{ subitem.label }}</a>
            </template>
          </template>
          <span style="display: block; height: 8px" />
        </div>
      </li>
    </template>
    <li
      v-if="props.sessionStore.name "
      class="nav-item nav-user"
      ref="navItem"
      @click="methods.onClickUser"
    >
      <a
        href="#"
        class="menu-user"
        @click="onClickMenu"
      >
        <user-logo />
        <span
          class="menu-name"
          style="margin-left: 10px"
        >{{ props.sessionStore.name }}</span>
      </a>

      <div
        class="dropdown"
        ref="dropdown"
      >
        <a
          href="#"
          class="user-submenu"
          @click="props.signOut"
        >Sair</a>
      </div>
    </li>
  </ul>
</template>

<style lang="sass" scoped>
  .navbar
    background-color: #3d55ae
    color: white
    display: flex
    padding: 0
    width: 100%

    .menu-name
      width: inherit
      margin-right: 0

    .menu-trick
      background-color: #3d55ae
      border-bottom-right-radius: 110px
      border-top-right-radius: 110px
      display: block
      height: 40px
      margin-left: -1px
      transition: background-color 0.3s, color 0.3s
      width: 20px

    .nav-list
      align-items: center
      display: flex
      list-style-type: none
      margin: 0
      padding: 0
      width: 100%

    .nav-item
      &.fill
        width: fill-available
        width: -webkit-fill-available
      a
        &.menu, &.link-menu, &.menu-user
          align-items: center
          color: white
          display: flex
          font-family: Work-sans-bold
          font-size: 13px
          height: 40px
          justify-content: center
          text-decoration: none
          width: max-content
        &.menu-user
          padding: 0 10px

        &.menu.active
          background-color: #fd9802
          border-bottom-right-radius: 110px
          border-top-right-radius: 110px
          color: #fff
          transition: background-color 0.3s, color 0.3s

        &.menu.force-active
          background-color: #fd9802
          border-bottom-right-radius: 110px
          border-top-right-radius: 110px
          color: #fff
          transition: background-color 0.3s, color 0.3s

        &.link-menu.active,&.menu-user.active,&.link-menu.force-active
          background-color: #fd9802
          color: #fff
          transition: background-color 0.3s, color 0.3s

    .nav-item .dropdown
      background-color: #ffffff
      border-radius: 0 0 7px 7px
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2)
      display: block
      max-height: 0
      overflow-y: auto
      position: absolute
      transition: max-height 0.3s ease-out
      width: max-content
      z-index: 1

      .nav-title
        color: #9f9f9f
        font-size: 10px
        font-weight: 500
        padding: 4px 20px

      .nav-separator
        border-top: 1px solid #d9d9d9
        display: block
        margin: 8px 20px
        width: auto

      a.submenu
        border-bottom-left-radius: 10px
        border-bottom-right-radius: 110px
        border-top-left-radius: 10px
        border-top-right-radius: 110px
        color: #6f6f6f
        display: block
        display: block
        font-family: Work-sans
        font-size: 13px
        font-weight: 400
        margin-left: 10px
        margin-right: 10px
        padding: 2px 10px
        text-decoration: none
        transition: opacity 0.3s ease-out 0.3s, background-color 0.3s, color 0.3s

        &:hover
          background-color: #3d55ae
          color: #fff
          transition: opacity 0.3s ease-out 0.3s, background-color 0.3s, color 0.3s

        &.active
          background-color: #3d55ae
          color: #fff
          transition: opacity 0.3s ease-out 0.3s, background-color 0.3s, color 0.3s

      a.user-submenu
        color: #6f6f6f
        display: block
        font-family: Work-sans
        font-size: 13px
        font-weight: 400
        margin: 0
        padding: 5px 10px
        text-decoration: none

        display: block
        transition: opacity 0.3s ease-out 0.3s, background-color 0.3s, color 0.3s

        &:hover
          color: #fff
          background-color: #3d55ae
          transition: opacity 0.3s ease-out 0.3s, background-color 0.3s, color 0.3s

    .item-margin:not(:first-child)
      margin-left: 1px

    .nav-item .dropdown
      &::-webkit-scrollbar
        width: 5px

      &::-webkit-scrollbar-track
        background: #f1f1f1
        border-radius: 5px

      &::-webkit-scrollbar-thumb
        background: #809bff
        border-radius: 5px

        &:hover
          background: #678bfc
          border-radius: 5px

    .nav-user
      margin-left: 10px
      margin-right: 10px

  @media (max-width: 930px)
    .menu-name
      display: none

    .navbar
      .nav-item
        a.menu-user
          min-width: 40px

        .dropdown
          a.user-submenu
            text-align: center
            padding: 5px

</style>
